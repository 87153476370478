/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <footer className="footer">
      <Container>
        {<nav>
          <ul>
            <li>
              <a
                target="_blank"
              >
              
              </a>
            </li>
            <li>
              <a
                target="_blank"
              >
              <div className="copyright" id="copyright">
          © {new Date().getFullYear()},         
           Diseñado por Fabian Martinez.{" "}
        </div>
                FBM Fattoria +584120684837
                        
              </a>
            </li>
            <li>
              <a
                target="_blank"
              >
                      Version 2.0

              </a>

            </li>
          </ul>
        </nav> }

      </Container>
    </footer>
  );
}

export default TransparentFooter;
