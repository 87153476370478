//Tipos de salidas 
module.exports = {

    code: {
        banesco:1,
        provincial: 2,
        venezuela: 3,
        bicentenario: 4,
        mercantil: 5,
        bod: 6,
    },

    bank: {
        1:'BANESCO',
        2:'PROVINCIAL', 
        3:'VENEZUELA',
        4:'BICENTENARIO',
        5:'MERCANTIL',
        6:'BOD',
    },

    account: {
        1:'PRINCIPAL',
        //2:'MOHAN 2025',
    // 3:'EMBUTIDOS MOHAN',
      //  4:'DELICATESES MOMOY',
        //5:'DELICATESES ENMANUEL',
        //6:'DANIEL PERSONAL',
        //7:'YONATHAN PERSONAL',
        8:'PERSONAL'
    },
}